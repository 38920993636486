export const ease = t => t * (2 - t)

export const tick = (duration, fn, to, from, delta) => {
  let time
  const resultFn = d => {
    if (!time) time = d
    const f = (d - time) / duration

    if (f >= 1) {
      return fn(to)
    }
    fn(from + ease(f) * delta)
    return global.window.requestAnimationFrame(resultFn)
  }
  return resultFn
}

export const animate = (from, to, duration, callback) => {
  const delta = to - from

  const tickFn = tick(duration, callback, to, from, delta)

  if (typeof window !== 'undefined') {
    if (global.window.requestAnimationFrame) {
      global.window.requestAnimationFrame(tickFn)
    } else {
      callback(to)
    }
  }
}
