import { useCallback, useEffect, useState } from 'react'
import inViewport from 'in-viewport'

export const useAppearedInView = ref => {
  const [inView, setInView] = useState(false)

  const handleScroll = useCallback(() => {
    if (!inView) {
      const inViewportVal = inViewport(ref?.current)
      setInView(inViewportVal)
    }
  }, [ref, inView, setInView])

  useEffect(() => {
    if (inView || !ref.current) return
    const inViewportVal = inViewport(ref.current)
    setInView(inViewportVal)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, inView, ref])

  return inView
}

export const useAppearedInViewById = id => {
  const [inView, setInView] = useState(false)
  const node = typeof document === 'undefined' ? null : document.getElementById(id)
  const handleScroll = useCallback(() => {
    if (!inView) {
      const inViewportVal = inViewport(node)
      setInView(inViewportVal)
    }
  }, [node, inView, setInView])

  useEffect(() => {
    if (inView || !node) return
    const inViewportVal = inViewport(node)
    setInView(inViewportVal)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, inView, node])

  return inView
}
