import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { animate } from './ui'
import throttle from 'client/common/throttle'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const settings = {
  carouselClassName: 'mxd-scroll-carousel',
  carouselItem: 'mxd-scroll-carousel-component-thin',
  scrollSnapItemsSelector: '.mxd-scroll-carousel-scrollpane > *',
  marginSize: 0
}

// copied from maakk-fe https://bitbucket.org/maxedadiy/maakk-frontend/src/master/common/components/organisms/ScrollCarousel/ScrollCarousel.js

const ScrollCarouselItem = ({ children, options }) => <div className={options.carouselItem}>{children}</div>

export const ScrollCarousel = ({ items, options: optionsProps = {} }) => {
  const options = { ...settings, ...optionsProps }

  const [scrollSize, setScrollSize] = useState(0)
  const [width, setWidth] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const carouselRef = useRef()
  const scrollContainerRef = useRef()

  const scrollItems = useCallback(() => {
    if (!carouselRef.current) return []
    return [...carouselRef.current.querySelectorAll(options.scrollSnapItemsSelector)]
    // eslint-disable-next-line
  }, [options.scrollSnapItemsSelector, options.itemsAmount])

  const recalculateProps = useCallback(() => {
    const scrollItemsArr = scrollItems()
    if (scrollItemsArr?.length > 0) {
      setWidth(scrollContainerRef.current.offsetWidth)
      const newItemWidth = scrollItemsArr[0].offsetWidth
      setItemWidth(newItemWidth)
      setScrollLeft(scrollContainerRef.current.scrollLeft)
      setScrollSize(Math.floor(width / newItemWidth) * newItemWidth)
    }
  }, [scrollItems, width])

  useEffect(() => {
    if (carouselRef.current) {
      window?.addEventListener('resize', recalculateProps)

      return () => {
        window?.removeEventListener('resize', recalculateProps)
      }
    }
  }, [carouselRef, options.scrollSnapItemsSelector, recalculateProps])

  useEffect(recalculateProps, [recalculateProps])

  if (!items.length || !items) return null

  const scrollTo = scrollTarget => {
    scrollTarget = Math.max(0, Math.min(maxScroll(), scrollTarget))

    animate(scrollLeft, scrollTarget, 500, newScrollLeft => {
      scrollContainerRef.current.scrollLeft = newScrollLeft
      setScrollLeft(scrollContainerRef.current.scrollLeft)
    })
  }

  const maxScroll = () => {
    const reduce = (result, col) => result + col.offsetWidth + options.marginSize
    return scrollItems().reduce(reduce, -scrollContainerRef?.current?.offsetWidth) - options.marginSize
  }

  const handleNext = () => scrollTo(Math.ceil(scrollLeft / scrollSize) * scrollSize + scrollSize)
  const handlePrev = () => scrollTo(Math.ceil((scrollLeft - scrollSize) / itemWidth) * itemWidth)

  return (
    <div
      className={options.carouselClassName}
      data-testid='carousel'
      ref={carouselRef}
      {...{
        ...(scrollLeft === 0 && { 'data-scroll-begin': true }),
        ...(scrollLeft >= maxScroll() && { 'data-scroll-end': true })
      }}
    >
      <div className='mxd-scroll-carousel-wrapper'>
        <div
          className='mxd-scroll-carousel-scrollpane'
          onScroll={e => {
            e.preventDefault()
            throttle(recalculateProps, 50)
          }}
          ref={scrollContainerRef}
        >
          {items.map((item, index) => (
            <ScrollCarouselItem key={`scp-${index}`} options={options}>
              {item}
            </ScrollCarouselItem>
          ))}
        </div>
        {options.NextButton ? (
          <options.NextButton onClick={handleNext} className='mxd-scroll-carousel-next' />
        ) : (
          <button data-testid='prev' className='mxd-scroll-carousel-next' onClick={handleNext}>
            <FaChevronRight fontSize='1.7em' />
          </button>
        )}
        {options.PrevButton ? (
          <options.PrevButton onClick={handlePrev} className='mxd-scroll-carousel-previous' />
        ) : (
          <button data-testid='next' className='mxd-scroll-carousel-previous' onClick={handlePrev}>
            <FaChevronLeft fontSize='1.7em' />
          </button>
        )}
      </div>
    </div>
  )
}

ScrollCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node)
}

export default ScrollCarousel
