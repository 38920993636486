import { useCallback, useEffect } from 'react'
import { ANALYTIC_LIST_TYPE_COOKIE, analyticForProductClick, X_SELL_PRODUCTS_LOADED } from './analytic'
import { setCookie } from 'chemistry'
import { dispatchPageBusEvent } from 'client/common/pageBus'

export const setAnalyticListType = (type, data) => {
  setCookie(ANALYTIC_LIST_TYPE_COOKIE, type, { path: '/' })
  if (data?.id) {
    const listData = [{ [data.id]: type }, ...JSON.parse(localStorage.getItem(ANALYTIC_LIST_TYPE_COOKIE) || '[]')]
    const listObj = listData.reduce((acc, item) => ({ ...acc, ...item }))
    const uniqueData = Object.entries(listObj).map(([key, val]) => ({
      [key]: val
    }))
    if (uniqueData.length > 100) uniqueData.length = 100
    localStorage.setItem(ANALYTIC_LIST_TYPE_COOKIE, JSON.stringify(uniqueData))
  }
}

export const useXSellAnalytic = (data, listType) => {
  useEffect(() => {
    if (!data?.products?.collection) return
    const event = {
      type: X_SELL_PRODUCTS_LOADED,
      payload: {
        listType,
        data: data?.products?.collection
      }
    }
    dispatchPageBusEvent(event)
  }, [data, listType])

  return useCallback(
    (props, index) => {
      setAnalyticListType(listType, props)
      analyticForProductClick(props, index, listType)
    },
    [listType]
  )
}
